@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    a {
        @apply text-bgBlue hover:opacity-70;
    }
}